@font-face {
  font-family: SuisseIntl-SemiBold;
  src: url("../assets/font/SuisseIntl-SemiBold.otf");
}

@font-face {
  font-family: SuisseIntl-Regular;
  src: url("../assets/font/SuisseIntl-Regular.otf");
}

@font-face {
  font-family: SuisseIntl-Thin;
  src: url("../assets/font/SuisseIntl-Thin.otf");
}

@font-face {
  font-family: Courier-Regular;
  src: url("../assets/font/CourierStd.otf");
}

html {
  scroll-behavior: smooth;
  background: #171717;
  background-color: #171717;
}

a,
span,
p,
li,
ul {
  color: white;
  font-family: SuisseIntl-Thin;
}

ul,
h1,
h2,
h3,
button,
b {
  color: white;
  font-family: SuisseIntl-Regular;
}


.bold {
  color: white;
  font-family: SuisseIntl-SemiBold;
}

h1 {
  font-size: 32px;
  line-height: 62px;
}

a {
  cursor: pointer;
}

h2 {
  font-size: 28px;
  line-height: 32px;
}

h3 {
  font-size: 22px;
  line-height: 28px;
}


p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: white;
}

p a {
  color: #f67dbb;
}

br {
  line-height: 2em;
}

li {
  margin: 8px 0;
}

.grey {
  color: #d5d5d5;
}

.pink {
  color: #ff65b5;
}

.text-black {
  color: black;
}

.images {
  max-width: 800px;
  width: 100%;
}

.png {
  max-width: 800px;
  height: auto;
  display: inline-block;
  transform-origin: top left;
  position: relative;
  left: 0;
  margin-left: 0%;
  margin-top: 0%;
}

.inpage_hero_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.inpage_hero_box {
  width: 48%;
  margin-right: 2%;
}

.inpage_hero_img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

.blue {
  color: blue;
}


.black {
  color: #171717;
}

.bg-project-card {
  background-color: #7c7c7c1f;
  backdrop-filter: blur(11px);
  margin: 2px 2px 2px 2px;
}

.right-border {
  border-right: 1px solid lightgray;
  padding: 0px 16px 0px 0px;
}



.card {
  display: inline-block;
  box-shadow: 1px 2px 24px 0 #BDBBBE;
  height: 22em;
  width: 40%;
  overflow: hidden;
  z-index: 2;
  cursor: pointer;
  margin-left: 2em;
  margin-top: 3em;
  max-width: 500px;
  height: 44vw;
  max-height: 450px;
}

.Card-content {
  position: absolute;
  margin-left: 2em;
  width: 90%;
}

.content {
  top: 2em;
  left: 0em;
  width: auto;
  margin-left: 4vw;
  margin-bottom: 3em;
}

.left-nav {
  width: 100pt;
  border: 0;
  outline: 0;
  z-index: 8;
  position: fixed;
  right: 1vw;
  top: 0px;
  padding: 4px 16px;
  height: 80vh;
  z-index: 0;
}

button {
  background: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}

.backbtn:hover {
  background: initial;
  font-weight: bold;
  background-color: #dc7dae36;
}

.inpage_menu_item {
  font-size: 13px;
  color: white;
  border: solid 1px transparent;
  text-align: left;
  padding: 8px 8px;
  border-radius: 4px;
}

.backbtn {
  font-size: 16px;
}


.icon {
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 8px;
}


.left-nav ul {
  margin: 80pt 0px;
  padding-left: 0px;

}

.left-nav li {
  list-style-type: none;
  margin: 0px 0px;

}

.left_nav_title {
  font-size: 16px;
  font-weight: bolder;
  margin-left: 8px;
  margin-bottom: 8px;
}

li a {
  text-decoration: none;
}

a:hover {
  color: #f467b0;
}

.landing-hero {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 60%;
  z-index: 1;
}

.page-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: scroll;
  left: 0;
  top: 0;
  scroll-behavior: smooth;
}

.page-container h1 {
  font-size: 52px;
}

.content-layout {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  padding: 100px 4em;
  max-width: 56em;
  width: 70%;
  height: 100%;
  z-index: 0;
}

.content-block {
  margin-top: 4em;
}

.seeMore {
  width: 70%
}

#recommend {
  position: absolute;
  width: 100%;
}

#info {
  position: absolute;
  left: 25%;
  width: 70%;
}

.descrip {
  position: absolute;
  width: 95%;
  margin-top: -.5em;
  font-size: 13px;
}


.dropdown {
  display: none;
}

.IsabellaW {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-size: cover;

}

.contact-text {
  position: absolute;
  width: 40%;
  background-color: white;
  padding: 2em 2em;
  margin-left: 48%;

}

.HomeIntro {
  font-size: 48px;
  font-weight: lighter;
  left: 0;
  width: 90%;
  background-color: #efefee;
  padding: 2em 1em;

}

.navigationMargin {
  margin-right: 24px;
  margin-top: 4px;
  cursor: pointer;
  text-decoration: none;
}

.AboutProj {
  text-decoration: none;
  min-width: 180px;
}

.AboutProj-content {
  padding: 8px 16px;
  margin-right: 24px;
  border-radius: 4px;
  color: black;
  background-color: #ff9ed0dc;
  backdrop-filter: blur(10px);
}

.AboutProj-content:hover {
  background-color: #f874b8;
}

.threecolumn {
  display: flex;
  margin-top: 48px;
}

.projectRow {
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow: hidden;
}

/* Homepage CSS */

#HP_container {
  display: inline-block;
  flex-direction: row;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 17;
  position: absolute;
  color: white;
  /* background-color: rgba(255, 182, 193, 0.507); */
  background-color: #171717;
}

#landingPart,
#contactPart {
  /* margin-top: 10%; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
  width: 80%;
  height: 100%;
  z-Index: 2;
  align-content: center;
  justify-content: center;

  /* background-color: rgba(250, 220, 224, 0.4); */
}

.landingpage_Intro {
  font-size: 6em;
  letter-spacing: -0.03em;
  align-self: center;
  vertical-align: middle;
  color: white;
  margin-left: 10%;
  font-family: 'SuisseIntl-Semibold';
}

#contactPart {
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
}

.Alllinks {
  font-size: 21px;
}

.HP_Intro {
  font-size: 48px;
  margin-top: 12px;
  font-family: 'SuisseIntl-SemiBold';
  z-index: 10;
}



.HP_descrip,
.Alllinks {
  font-size: 21px;
  margin-top: 24px;
  font-family: 'SuisseIntl-Regular';
  z-index: 10;
  line-height: 42px;
  width: 100%;
}

.sessionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  left: 0;
  position: relative;
  overflow: hidden;
  z-index: 100;
  padding: 54pt 0pt;
  width: 100%;
  height: 400pt;
}

.contact_me_inpage {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 400pt;
}

.contact_me_inpage .icons {
  justify-content: space-evenly;
  width: 160pt;
  display: flex;
  flex-direction: row;
}



.contentblock {
  width: 100%;
  max-width: 400pt;
  align-content: space-around;
  justify-content: space-around;
}

.textBlock {
  width: 36%;
  padding: 2%;
  margin-right: 0;
  align-content: space-around;
  justify-content: space-around;
  overflow: hidden;
}

.img {
  height: auto;
  width: 80%;
  margin-left: 8%;
}

.pinkSquare {
  margin-left: 0px;
}

.timeLine {
  margin-left: 40px;
}


.DODCornellImg {
  width: 100%;
  margin-left: 0%;
}

.awareHealthText {
  margin-left: 5%;
  width: 40%;
}

.threecolumn-row {
  width: 50%;
}



.contentblock h1 {
  margin: auto 16pt;
}

.contentblock p {
  margin: auto 16pt;
}

.contentblock .readCases {
  margin: auto 8pt;
}


/* Loading animation */

.loader {
  display: inline-block;
  font-size: 2em;
  margin: 0 .5em;
  position: relative;
  height: .5em;
  width: 1em;
}

.loader:before,
.loader:after {
  content: "";
  display: block;
  height: .5em;
  position: absolute;
  width: .5em;
}

.loader:before {
  -webkit-animation: load-before 2s ease-in-out infinite, zoom-before .66s ease-in-out infinite;
  -moz-animation: load-before 2s ease-in-out infinite, zoom-before .66s ease-in-out infinite;
  -ms-animation: load-before 2s ease-in-out infinite, zoom-before .66s ease-in-out infinite;
  -o-animation: load-before 2s ease-in-out infinite, zoom-before .66s ease-in-out infinite;
  animation: load-before 2s ease-in-out infinite, zoom-before .66s ease-in-out infinite;
  background: #FC2293;
}

.loader:after {
  -webkit-animation: load-after 2s ease-in-out infinite, zoom-after .66s ease-in-out infinite;
  -moz-animation: load-after 2s ease-in-out infinite, zoom-after .66s ease-in-out infinite;
  -ms-animation: load-after 2s ease-in-out infinite, zoom-after .66s ease-in-out infinite;
  -o-animation: load-after 2s ease-in-out infinite, zoom-after .66s ease-in-out infinite;
  animation: load-after 2s ease-in-out infinite, zoom-after .66s ease-in-out infinite;
  background: #C1C1C1;
}

.small {
  font-size: 1em;
}

.large {
  font-size: 4em;
}

.circle:before,
.circle:after {
  border-radius: 50%;
}

.circle-before:before {
  border-radius: 50%;
}

.circle-after:after {
  border-radius: 50%;
}

/* ---------- ANIMATION WEBKIT ---------- */

@-webkit-keyframes load-before {
  0% {
    left: 0;
    -webkit-transform: scale(1.1);
  }

  50% {
    left: 100%;
    -webkit-transform: scale(1);
  }

  100% {
    left: 0;
    -webkit-transform: scale(1.1);
  }
}


@-webkit-keyframes load-after {
  0% {
    left: 100%;
    -webkit-transform: scale(1.1);
  }

  50% {
    left: 0;
    -webkit-transform: scale(1);
  }

  100% {
    left: 100%;
    -webkit-transform: scale(1.1);
  }

}

@-webkit-keyframes zoom-before {
  0% {
    z-index: 0;
  }

  50% {
    z-index: 1;
  }

  100% {
    z-index: 0;
  }
}

@-webkit-keyframes zoom-after {
  0% {
    z-index: 1;
  }

  50% {
    z-index: 0;
  }

  100% {
    z-index: 1;
  }
}

/* ---------- ANIMATION MOZ ---------- */

@-moz-keyframes load-before {
  0% {
    left: 0;
    -moz-transform: scale(1.1);
  }

  50% {
    left: 100%;
    -moz-transform: scale(1);
  }

  100% {
    left: 0;
    -moz-transform: scale(1.1);
  }
}


@-moz-keyframes load-after {
  0% {
    left: 100%;
    -moz-transform: scale(1.1);
  }

  50% {
    left: 0;
    -moz-transform: scale(1);
  }

  100% {
    left: 100%;
    -moz-transform: scale(1.1);
  }

}

@-moz-keyframes zoom-before {
  0% {
    z-index: 0;
  }

  50% {
    z-index: 1;
  }

  100% {
    z-index: 0;
  }
}

@-moz-keyframes zoom-after {
  0% {
    z-index: 1;
  }

  50% {
    z-index: 0;
  }

  100% {
    z-index: 1;
  }
}

/* ---------- ANIMATION MS ---------- */

@-ms-keyframes load-before {
  0% {
    left: 0;
    -ms-transform: scale(1.1);
  }

  50% {
    left: 100%;
    -ms-transform: scale(1);
  }

  100% {
    left: 0;
    -ms-transform: scale(1.1);
  }
}


@-ms-keyframes load-after {
  0% {
    left: 100%;
    -ms-transform: scale(1.1);
  }

  50% {
    left: 0;
    -ms-transform: scale(1);
  }

  100% {
    left: 100%;
    -ms-transform: scale(1.1);
  }

}

@-ms-keyframes zoom-before {
  0% {
    z-index: 0;
  }

  50% {
    z-index: 1;
  }

  100% {
    z-index: 0;
  }
}

@-ms-keyframes zoom-after {
  0% {
    z-index: 1;
  }

  50% {
    z-index: 0;
  }

  100% {
    z-index: 1;
  }
}

/* ---------- ANIMATION O ---------- */

@-o-keyframes load-before {
  0% {
    left: 0;
    -o-transform: scale(1.1);
  }

  50% {
    left: 100%;
    -o-transform: scale(1);
  }

  100% {
    left: 0;
    -o-transform: scale(1.1);
  }
}


@-o-keyframes load-after {
  0% {
    left: 100%;
    -o-transform: scale(1.1);
  }

  50% {
    left: 0;
    -o-transform: scale(1);
  }

  100% {
    left: 100%;
    -o-transform: scale(1.1);
  }

}

@-o-keyframes zoom-before {
  0% {
    z-index: 0;
  }

  50% {
    z-index: 1;
  }

  100% {
    z-index: 0;
  }
}

@-o-keyframes zoom-after {
  0% {
    z-index: 1;
  }

  50% {
    z-index: 0;
  }

  100% {
    z-index: 1;
  }
}

/* ---------- ANIMATION ---------- */

@keyframes load-before {
  0% {
    left: 0;
    transform: scale(1.1);
  }

  50% {
    left: 100%;
    transform: scale(1);
  }

  100% {
    left: 0;
    transform: scale(1.1);
  }
}


@keyframes load-after {
  0% {
    left: 100%;
    transform: scale(1.1);
  }

  50% {
    left: 0;
    transform: scale(1);
  }

  100% {
    left: 100%;
    transform: scale(1.1);
  }

}

@keyframes zoom-before {
  0% {
    z-index: 0;
  }

  50% {
    z-index: 1;
  }

  100% {
    z-index: 0;
  }
}

@keyframes zoom-after {
  0% {
    z-index: 1;
  }

  50% {
    z-index: 0;
  }

  100% {
    z-index: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 1.5s ease-in-out forwards;
}



@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.fade-in {
  animation: fadeIn .5s ease-in-out forwards;
}

.loadingpage {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 40vh;
  height: 100vh;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #171717;
  background: #171717;
}


@media only screen and (max-width: 1100px) {
  .inpage_hero_container {
    flex-direction: column;
  }

  .inpage_hero_box {
    width: 100%;
    margin-right: 0;
  }

}

@media only screen and (max-width: 850px) {

  p,
  a {
    font-family: SuisseIntl-Regular;
  }

  .images {
    width: 100%;
  }

  .card {
    width: 80%;
    margin-left: 10%;
    margin-top: 1em;
    height: 76vw;
    min-height: 350px;
  }

  .contact-text {

    display: block;
    width: 90%;
    left: 0;
    margin-left: 0;
    margin-top: 50%;
    padding: 2em;
  }

  .venture {
    left: 36px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

  }

  .HP_Intro {
    font-size: 36px;
  }


  .IsabellaW {
    margin-top: 3em;
  }

  .campy {
    left: 48px;
    color: #1717178e
  }


  .inpage_hero_box {
    width: 100%;
  }



  .HP_Intro {
    font-size: 28px;
  }

  .HP_descrip,
  .Alllinks {
    font-size: 18px;
  }

  .DODCornell {
    align-items: center;
    flex-direction: column;
    top: 0
  }

  .contentblock {
    width: 80%;
  }

  #landingPart,
  #contactPart {
    width: 90%;
  }

  #contactPart {
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
  }

  .landingPart,
  .contactPart {
    flex-direction: row;
    align-self: start;

  }


  .threecolumn {
    display: flex;
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }

  .threecolumn-row {
    width: 100%;
  }

  .AboutProj {
    width: 100%;
    min-width: 300px;
  }

  .seeMore {
    width: 86vw;
  }

  /* Content page */
  .left-nav {
    width: 90%;
    z-index: 8;
    position: relative;
    height: auto;
    top: 0px;
    border-right: 0px solid #fc229338;
    border-left: 2px solid #fc229338;
  }

  .left-nav ul {
    margin: 18px 4px;
    font-size: 24px;
  }

  .left-nav li {
    font-size: 18px;
  }

  .left_nav_title {
    font-size: 22px;
  }

  .inpage_menu_item {
    font-size: 16px;
  }

  .content-layout {
    width: auto;
    margin-top: 24px;
    margin-left: 0%;
    padding: 100px 24px;
  }

  .content-block {
    margin-top: 2em;
  }

  .dropdown {
    display: inherit;
    position: fixed;
    right: 5%;
    color: #FC2293;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 1vw;
    font-size: 12px;
    background-color: white;
  }


  .venture {
    left: 12px;
  }

  .card {
    width: 90%;
    margin-left: 2%;
    margin-top: 1em;
  }

  .Card-content {
    position: absolute;
    margin-left: 1em;
    width: 90%;
  }


  .img {
    width: 70%;
  }


  #landingPart,
  #contactPart {
    width: 100%;
  }

  .landingpage_Intro {
    font-size: 5em;
    line-height: 1.2;
    margin-left: 5%;
  }

  .AboutProj-content {
    margin-right: 0px;
  }

  .navigationMargin {
    margin-right: 0;
  }

  .contact_me_inpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .contact_me_inpage .icons {
    width: 100%;
  }

  .projectRow {
    flex-direction: column;
  }

  .contentblock h1 {
    margin: auto 0;
  }
  
  .contentblock p {
    margin: auto 0;
  }
  
  .contentblock .readCases {
    margin: auto -8pt;
  }
  


}